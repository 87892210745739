import React from "react"

import SEO from "../components/seo"

const LegalNotice = () => {
  return (
    <article className="article">
      <SEO title="Legal Notice" />
      <h1>
        <strong>TERMS AND CONDITIONS OF USE</strong>
      </h1>
      <p>
        The terms and conditions of us set forth below (the &ldquo;Terms&rdquo;)
        apply to all webpages on this Website (collectively, the
        &ldquo;Website&rdquo;).
      </p>
      <p>&nbsp;</p>
      <p>
        Please read these Terms carefully before you use the Website. By
        accessing or using the Website, you acknowledge that you have read,
        understood, accepted and agreed to be bound by these Terms. If you do
        not agree to any of the Terms, you are not an authorized user of this
        Website. Unauthorized use of this Website is strictly prohibited. You
        may be denied access to the Website, without prior notice to you, if you
        do not comply with any of these Terms.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Licence to use the Website</strong>
      </p>
      <p>
        Dobacor Management LLC (&lsquo;Dobacor&rdquo; or &ldquo;we&rdquo;)
        grants you a limited licence to access and use the Website subject to
        the condition that you agree to abide by these Terms. Your compliance
        with these Terms is a condition to your right to access the Website.
        Your breach of any provision of the Terms will automatically revoke and
        terminate your right to access the Website without notice to you and you
        will be liable for conversion, misappropriation, trespass to chattels
        and any other claims arising from or in connection with your authorized
        use of the Website.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>No offer/solicitation </strong>
      </p>
      <p>
        The information on the Website is solely for informational purpose only.
        Nothing contained in or on the Website constitutes investment,
        financial, accounting, legal or tax advice. No information contained on
        this Website shall be deemed a recommendation or is to be relied on for
        the purpose of making or communicating investment or other decisions.
        Neither the information contained in the Website constitutes a
        solicitation or offer by Dobacor or any entity managed directly or
        indirectly by Dobacor, to buy or offer, or recommendation, to acquire or
        dispose of any security, commodity or investment or to engage in any
        other transaction. Any formal offer of product or security will only be
        accompanied by formal offering documentation or other definitive
        materials that have been prepared in accordance with applicable law
        specifically for the purposes of making such offer. Decision based on
        the information contained on the Website are the sole responsibility of
        the visitor.
      </p>
      <p>&nbsp;</p>
      <p>
        No investment products are offered or sold in any jurisdiction, or in
        any manner in which an investment would be contrary to the applicable
        securities laws or any other local laws or regulations. Applications
        from persons who do not meet applicable eligibility or suitability
        requirements will be refused. In no event Dobacor shall be responsible
        or liable for the loss and damage of any kind whatever arising directly
        or indirectly as a result of the content and the information on this
        Website.
      </p>
      <p>&nbsp;</p>
      <p>
        This Website may contain forward-looking statements with respect to
        Dobacor&rsquo;s projects and future results. Forward-looking statements
        are often identified by words such as &ldquo;anticipate&rdquo;,
        &ldquo;estimate&rdquo;, &ldquo;expect&rdquo;, &ldquo;believe&rdquo;,
        &ldquo;predict&rdquo;, &ldquo;intend&rdquo;, &ldquo;should&rdquo;,
        &ldquo;may&rdquo;, &ldquo;plan&rdquo; and similar expressions. Actual
        results may differ from expected results for a variety of reasons and
        past performance is not indicative of future results. While Dobacor uses
        reasonable efforts to obtain information from reliable sources, Dobacor
        makes no representations or warranties about the accuracy or
        completeness of the Website or any of its content obtained outside of
        Dobacor.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>No Warranty, Limitation of Liability</strong>
      </p>
      <p>
        THE INFORMATION ON THE WEBSITE is provided ON AN AS-IS AND AS-AVAILABLE
        BASIS. DOBACOR DOES NOT WARRANT THE ACCURACY OF THE MATERIALS PROVIDED
        (EITHER EXPRESSLY OR IMPLIEDLY) ON THE WEBSITE FOR ANY PARTICULAR
        PURPOSE AND EXPRESSLY DISCLAIMS ANY WARRANTIES OF MERCHANTABILITY OR
        FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
      </p>
      <p>
        DOBACOR MAKES NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR
        COMPLETENESS OF THE WEBSITE&rsquo;S CONTENT OR THE CONTENT OF ANY
        WEBSITES LINKED TO THIS SITE AND DOBACOR WILL ASSUME NO LIABILITY OR
        RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT
        AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE
        WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE WEBSITE, (3)
        ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND
        ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN,
        (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE
        WEBSITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE
        TRANSMITTED TO OR THROUGH THE WEBSITE BY ANY THIRD PARTY, AND/OR (6) ANY
        ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR
        DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT
        POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE WEBSITE.
        DOBACOR DOES NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY
        FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY
        THROUGH THE WEBSITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE
        APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND DOBACOR
        WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY
        TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR
        SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY
        MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND
        EXERCISE CAUTION WHERE APPROPRIATE.
      </p>
      <p>&nbsp;</p>
      <p>
        IN NO EVENT WILL DOBACOR OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE
        LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
        CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES,
        INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES
        ARISING FROM YOUR USE OF THE WEBSITE, EVEN IF WE HAVE BEEN ADVISED OF
        THE POSSIBILITY OF SUCH DAMAGES. DOBACOR SHALL HAVE NO RESPONSIBILITY TO
        INFORM OR NOTIFY YOU OF ANY DIFFICULTIES EXPERIENCED BY DOBACOR OR ANY
        THIRD PARTIES WITH RESPECT TO THE USE OF THE SERVICES, PRODUCTS OR DATA
        PROVIDED HEREIN.
      </p>
      <p>&nbsp;</p>
      <p>
        YOU ACKNOWLEDGE THAT NOTHING CONTAINED ON THIS WEBSITE (INCLUDING BUT
        NOT LIMITED TO STRATEGIES AND RESEARCH, DAILY WIRES AND EDUCATIONAL
        ARTICLES) CONSTITUTES INVESTMENT, FINANCIAL, LEGAL, TAX OR OTHER ADVICE,
        NOR IS IT TO BE RELIED UPON IN MAKING ANY INVESTMENT OR OTHER DECISIONS.
        YOU SHOULD SEEK PROFESSIONAL ADVICE PRIOR TO MAKING ANY INVESTMENT
        DECISIONS.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>Copyright and Trademarks</strong>
      </p>
      <p>
        All copyrights, trademarks, service marks, trade names, and logos
        contained on the Website are owned, controlled or licensed by or to
        Dobacor, and are protected by trade dress, copyright, patent and
        trademark laws, and various other intellectual property rights and
        unfair competition laws.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>Disclosure regarding Dobacor Social Media Channels</strong>
      </p>
      <p>
        The content on Dobacor&rsquo;s social media channels, including
        Facebook, Instagram and LinkedIn is provided as information only. It
        does not constitute a solicitation or offer by Dobacor to buy or offer,
        or recommendation, to acquire or dispose of any security, commodity or
        investment or to engage in any other transaction. Dobacor does not
        warrant the accuracy and completeness of the information available on
        its social media channels. Dobacor does not control and is not
        responsible for any third party content on such channels or on other
        third party sites that may be linked to by such channels. Dobacor is not
        responsible for the ramifications of your use of such channels or such
        third party content or sites, or for any third party&rsquo;s policies or
        practices regarding security, privacy, use or other matters.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Indemnification</strong>
      </p>
      <p>
        You hereby agree to indemnify, defend and hold harmless Dobacor from and
        against all liabilities, claims, losses, damages and costs arising from
        or involving your use, or the use of the Website or related services or
        from your violation of these Terms.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Severability</strong>
      </p>
      <p>
        If any of the provisions of these Terms are deemed unlawful or for any
        reason unenforceable, the same shall be inoperative only to the extent
        necessary to achieve compliance with applicable law and shall not affect
        the remaining Terms, which shall be given full effect, without regard to
        the invalid portion(s).
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Governing Law</strong>
      </p>
      <p>
        These Terms shall be governed by and construed in accordance with the
        laws of the United States and by the laws of the State of California.
        Any claim related to any dispute arising as a result of the Website
        shall be made before a court of competent jurisdiction located in
        California.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>Updates to the Website</strong>
      </p>
      <p>
        Dobacor reserves the right to change, modify, add or remove portions of
        the Terms at any time for any reason and in our sole discretion without
        notice. Your use of the Website is governed by the version of the Terms
        in effect on the date the Website is accessed by you.
      </p>
      <p>
        It is your responsibility to periodically review these Terms to stay
        informed of updates. You will be subjected to, and will be deemed to
        have been made aware of and to have accepted, the changes in any revised
        Terms of Use by your continued use of the Website after the date such
        revised Terms are posted.
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>How To Contact Us</strong>
      </p>
      <p>
        To resolve a complaint regarding the Website or to receive further
        information regarding use of the Website, please contact us at:
      </p>
      <p>DOBACOR MANAGEMENT LLC</p>
      <p>141 S Robertson Blvd, Suite 200</p>
      <p>Los Angeles, CA 90048 USA</p>
      <p>Phone: 1 310 993-5654</p>
      <p>
        Email: <a href="mailto:info@dobacor.com">info@dobacor.com</a>
      </p>
      <p>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>California Users and Residents</strong>
      </p>
      <p>
        If any complaint with us is not satisfactorily resolved, you can contact
        the Complaint Assistance Unit of the Division of Consumer Services of
        the California Department of Consumer Affairs in writing at 1625 North
        Market Blvd., Suite N 112, Sacramento, California 95834 or by telephone
        at (800) 952-5210 or (916) 445-1254.
      </p>
      <p>&nbsp;</p>
      <p>Last updated on September, 04, 2020.</p>
    </article>
  )
}

export default LegalNotice
